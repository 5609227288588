<template>
  <div>
    <b-row>
      <b-col>
        <b-section
          id="dashboard-ecommerce"
        >
          <b-card
            v-if="loadFaturamentoDia"
            id="space-h"
            style="width: 100%; height: 400px;"
          ><b-overlay
            :show="loadFaturamentoDia"
            style="margin-top: 200px;"
          />
          </b-card>
          <b-card
            v-if="dadosGraficosFaturamentoDia"
            :title="`Faturamento por Mes - R$ ${valorBr(totalFaturamentoDia)}`"
          >
            <b-container>
              <b-row
                md="4"
                class="budget-wrapper"
                align-h="start"
              >
                <b-col cols="3">
                  <v-select
                    v-model="selectedYear"
                    class="mt-2"
                    :options="years"
                    @input="armazenaAno"
                  />
                </b-col>
                <b-col cols="3">
                  <v-select
                    v-model="selectedMonth"
                    class="mt-2"
                    :options="months"
                    @input="getMonth"
                  />
                </b-col>
              </b-row>
            </b-container>
            <vue-apex-charts
              type="line"
              height="350"
              :options="chartOptionsFaturamentoDia"
              :series="seriesFaturamentoDia"
            />
          </b-card>
        </b-section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BOverlay,
  BContainer,
} from 'bootstrap-vue'

import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'

import api from '../../../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueApexCharts,
    vSelect,
    BOverlay,
    BContainer,
  },
  props: {
    generalData: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      ano: null,
      dadosGraficosFaturamentoMes: false,
      dadosGraficosFaturamentoDia: false,
      loadFaturamentoMes: true,
      loadFaturamentoDia: true,
      totalFaturamentoMes: 0,
      totalFaturamentoDia: 0,
      selectedYear: null,
      selectedMonth: null,
      years: [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
      months: [
        { label: 'Janeiro', value: 1 },
        { label: 'Fevereiro', value: 2 },
        { label: 'Março', value: 3 },
        { label: 'Abril', value: 4 },
        { label: 'Maio', value: 5 },
        { label: 'Junho', value: 6 },
        { label: 'Julho', value: 7 },
        { label: 'Agosto', value: 8 },
        { label: 'Setembro', value: 9 },
        { label: 'Outubro', value: 10 },
        { label: 'Novembro', value: 11 },
        { label: 'Dezembro', value: 12 },
      ],
      seriesFaturamentoDia: [{
        name: 'Q° de Clientes',
        type: 'column',
        data: [],
        color: '#ff6900',
      }, {
        name: 'Q° de Parcelas',
        type: 'column',
        data: [],
        color: '#6610f2',
      }, {
        name: 'Valor Realizado',
        type: 'line',
        data: [],
        color: '#00d084',
      }],
      chartOptionsFaturamentoDia: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 4],
        },
        title: {
          text: '',
          align: 'left',
          offsetX: 110,
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#ff9f43',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
            },
            title: {
              text: 'Q° de Clientes',
              style: {
                color: '#008FFB',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: 'Q° de Clientes',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#6610f2',
            },
            labels: {
              style: {
                colors: '#00E396',
              },
            },
            title: {
              text: 'Q° de Parcelas',
              style: {
                color: '#00E396',
              },
            },
          },
          {
            seriesName: 'Valor Realizado',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#6610f2',
            },
            labels: {
              style: {
                colors: '#FEB019',
              },
            },
            title: {
              text: 'Valor Realizado',
              style: {
                color: '#6610f2',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
    }
  },

  mounted() {
    dayjs.locale('pt-br')
  },

  created() {
    this.ano = dayjs().year()
    this.getMonth()
    this.getGraficoFaturamentoDia(this.ano, { value: dayjs().month() + 1 })
    this.selectedYear = this.ano
    const selectedMonthNumber = dayjs().month() + 1
    const selectedMonth = this.months.find(month => month.value === selectedMonthNumber)
    this.selectedMonth = selectedMonth.label
  },

  methods: {
    clearGraficoFaturamentoDia() {
      this.chartOptionsFaturamentoDia.xaxis.categories = []
    },
    getMonth(item) {
      if (item) {
        this.dadosGraficosFaturamentoDia = false
        this.loadFaturamentoDia = true
        this.getGraficoFaturamentoDia(this.ano, item)
      }
    },
    armazenaAno() {
      this.ano = dayjs().year()
    },
    getGraficoFaturamentoDia(years, months) {
      this.totalFaturamentoDia = 0
      this.seriesFaturamentoDia[0].data = []
      this.seriesFaturamentoDia[1].data = []
      this.seriesFaturamentoDia[2].data = []
      this.chartOptionsFaturamentoDia.xaxis.categories = []
      const body = {
        ano: years ? this.ano : dayjs().year(),
        mes: months.value,
      }
      api.post('api/v1/credores/faturamento/geral/dia', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const dt = res.data
        this.dadosGraficosFaturamentoDia = true
        this.loadFaturamentoDia = false
        dt.map(cliente => {
          this.seriesFaturamentoDia[0].data.push(cliente.total_clientes)
        })
        dt.map(parcelas => {
          this.seriesFaturamentoDia[1].data.push(parcelas.total_parcelas)
        })
        dt.map(valor => {
          this.seriesFaturamentoDia[2].data.push(this.valorBr(valor.total))
        })
        dt.map(dia => {
          this.chartOptionsFaturamentoDia.xaxis.categories.push(dia.dia)
        })
        dt.map(soma => {
          this.totalFaturamentoDia += parseFloat(soma.total)
        })
      })
    },
  },
}

</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
  </style>
