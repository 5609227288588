<template>
  <div>
    <b-row>
      <b-col>
        <b-container>
          <b-tabs
            horizontal
            content-class="col-12 col-md-12 mt-1 mt-md-0"
            pills
            nav-wrapper-class="col-md-12 col-12"
            nav-class="nav-left"
            align="center"
          >
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="AirplayIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Faturamento Mensal</span>
              </template>
              <b-section>
                <ChartFaturamentoMes
                  :general-data="credorId"
                />
              </b-section>
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="AirplayIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Faturamento Credor</span>
              </template>
              <b-row>
                <b-col>
                  <b-card
                    :title="'Escolha o Credor'"
                    @click="resetGraficos()"
                  >
                    <b-col
                      md="15"
                      mb="2"
                      class="budget-wrapper"
                    >
                      <v-select
                        v-model="credor"
                        class="mt-2"
                        :options="credores"
                        @input=" getCredorId"
                      />
                    </b-col>
                  </b-card>
                </b-col>
              </b-row>
              <b-section
                v-if="dadosGraficos"
              >
                <b-section
                  v-if="recarregaGraficos"
                  id="dashboard-ecommerce"
                >
                  <ChartFaturamentoTotal
                    v-if="dadosGraficos"
                    :general-data="credorId"
                  />
                  <ChartFaturamentoAnual
                    v-if="dadosGraficos"
                    :general-data="credorId"
                  />
                </b-section>
              </b-section>
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="AirplayIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Faturamento Executivo</span>
              </template>
              <b-row>
                <b-col>
                  <b-card
                    :title="'Escolha o Executivo'"
                    @click="resetGraficos()"
                  >
                    <b-col
                      md="15"
                      mb="2"
                      class="budget-wrapper"
                    >
                      <v-select
                        v-model="executivo"
                        class="mt-2"
                        :options="executivo_id"
                        @input=" getExecutivoId"
                      />
                    </b-col>
                  </b-card>
                </b-col>
              </b-row>
              <b-section
                v-if="dadosExecutivos"
              >
                <b-section
                  v-if="recarregaGraficosExecutivo"
                  id="dashboard-ecommerce"
                >
                  <b-card>
                    <b-card>
                      <ChartFaturamentoExecutivo

                        v-if="dadosExecutivos"
                        :general-data="executivoId"
                      />
                    </b-card>
                    <b-card>
                      <ChartFaturamentoExecutivoAnual

                        v-if="dadosExecutivos"
                        :general-data="executivoId"
                      />
                    </b-card>
                  </b-card>
                </b-section>
              </b-section>
            </b-tab>
            <b-tab />
          </b-tabs>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import ChartFaturamentoTotal from '../backoffice/credores/cards/charts/ChartFaturamentoTotal.vue'
import ChartFaturamentoAnual from '../backoffice/credores/cards/charts/ChartFaturamentoAnual.vue'
import ChartFaturamentoMes from '../backoffice/credores/cards/charts/ChartFaturamentoMes.vue'
import ChartFaturamentoExecutivoAnual from '../backoffice/credores/cards/charts/ChartFaturamentoExecutivoAnual.vue'
import ChartFaturamentoExecutivo from '../backoffice/credores/cards/charts/ChartFaturamentoExecutivo.vue'

import axios from '../../../axios-auth'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    vSelect,
    ChartFaturamentoTotal,
    ChartFaturamentoAnual,
    ChartFaturamentoMes,
    ChartFaturamentoExecutivoAnual,
    ChartFaturamentoExecutivo,
  },
  props: {
    generalData: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      mostrarGrafico: false,
      recarregaGraficos: false,
      recarregaGraficosExecutivo: false,
      dadosGraficos: false,
      dadosExecutivos: false,
      credores: [],
      executivo_id: [],
      credor: null,
      executivo: null,
      credorId: null,
      executivoId: null,
      selectedYear: null,
      years: [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
    }
  },

  async created() {
    this.getCredores()
    this.getExecutivos()
  },
  methods: {
    resetGraficos() {
      this.dadosGraficos = false
      this.dadosExecutivos = false
    },
    getCredorId() {
      this.credorId = this.credor.id
      this.dadosGraficos = true
      this.recarregaGraficos = true
      this.mostrarGrafico = true
    },
    getExecutivoId() {
      this.executivoId = this.executivo.id
      this.dadosGraficos = true
      this.recarregaGraficosExecutivo = true
      this.dadosExecutivos = true
      this.mostrarGrafico = true
    },
    async getCredores() {
      await axios.get('api/v1/credores/listar/false', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.credores.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    async getExecutivos() {
      await axios.get('api/v1/executivo', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.map(item => {
          this.executivo_id.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
  </style>
